body, html {
}

.App {
    font-family: "Trebuchet MS";
    position: relative;

}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.anim, .anim2 {
    height: 340px;
}

.tp-top-bar-fixed {
    position: fixed;
    width: 100%;
    display: block;
    background: #FFFFFF;
    z-index: 1000;
}

.top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;

}

a {
    color: #000000;
    text-decoration: none
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.ring-right, .ring-left {
    fill: #FFFFFF;
    stroke: #000000;
    stroke-width: 28;
    stroke-miterlimit: 10;
}

.tag-up, .tag-down {
    fill: none;
    stroke: #000000;
    stroke-width: 28;
    stroke-miterlimit: 10;
}

.anim .ring-right, .anim .ring-left {
    animation: Enabled-Splash infinite 20s linear;
}

.anim .tag-up {
    animation: Tag-Up infinite 20s linear;
}

.anim .tag-down {
    animation: Tag-Down infinite 20s linear
}

.anim2 .ring-right, .anim2 .ring-left {
    animation: Enabled-Splash-Glitch infinite 3s linear;
}

.anim2 .tag-up {
    animation: Tag-Up-Glitch infinite 3s linear
}

.anim2 .tag-down {
    animation: Tag-Down-Glitch infinite 3s linear
}

.movingLogo {
    margin-top: 100px;
}

#Logo {
    width: 100%;
    height: auto;
    background: #FFFFFF;
}

.logo-fix {
    margin: 0px 0px 0px 0px;
    position: fixed;
    background: #000;
    width: 100%;
    overflow: hidden;
    height: 100%;
}

@keyframes Enabled-Splash {
    0% {
        stroke-dasharray: 300;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 28 300;
        stroke-dashoffset: -166;
    }
    100% {
        stroke-dasharray: 300;
        stroke-dashoffset: 0;
    }

}

@keyframes Tag-Up {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(50px, -50px);
    }
    100% {
        transform: translate(0, 0);
    }

}

@keyframes Tag-Down {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-50px, 50px);
    }
    100% {
        transform: translate(0, 0);
    }

}

@keyframes Enabled-Splash-Glitch {
    0% {
        stroke-dasharray: 300;
        stroke-dashoffset: 0;
        stroke-width: 28;
    }
    10% {
        stroke-dasharray: 40 300;
        stroke-dashoffset: -180;
    }
    20% {
        stroke-dasharray: 40 300;
        stroke-dashoffset: -220;
    }
    30% {
        stroke-dasharray: 40 300;
        stroke-dashoffset: -270;
    }
    40% {
        stroke-dasharray: 40 300;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 40 300;
        stroke-dashoffset: -180;
        stroke-width: 3;
    }
    100% {
        stroke-dasharray: 300;
        stroke-dashoffset: 0;
    }

}

@keyframes Tag-Up-Glitch {
    0% {
        transform: translate(0, 0);
    }
    10% {
        transform: translate(80px, -80px);
    }
    20% {
        transform: translate(-43px, 43px);
    }
    40% {
        transform: translate(-43px, 43px);
    }
    45% {
        transform: translate(30px, -30px);
    }
    50% {
        transform: translate(50px, -50px);
    }
    60% {
        transform: translate(70px, -70px);
    }
    70% {
        transform: translate(60px, -60px);
    }
    75% {
        transform: translate(50px, -50px);
    }
    80% {
        transform: translate(0, 0);
    }

}

@keyframes Tag-Down-Glitch {
    0% {
        transform: translate(0, 0);
    }
    10% {
        transform: translate(-80px, 80px);
    }
    20% {
        transform: translate(43px, -43px);
    }
    40% {
        transform: translate(43px, -43px);
    }
    45% {
        transform: translate(-30px, 30px);
    }
    50% {
        transform: translate(-50px, 50px);
    }
    60% {
        transform: translate(-70px, 70px);
    }
    70% {
        transform: translate(-60px, 60px);
    }
    75% {
        transform: translate(-50px, 50px);
    }
    80% {
        transform: translate(0, 0);
    }


}

.logo {
    height: 40px;
    padding: 0.3em 2em 0.3em 0.3em;
}

.fly-logo {
    position: absolute;
    top: 500px;
    left: 200px;
}

.fly-logo1 {
    position: absolute;
    top: 600px;
    left: 300px;
}

.fly-logo2 {
    position: absolute;
    top: 600px;
    left: 400px;
}

.tp-story-container {
    color: #aaa;
    display: flex;
    align-items: start;
    align-content: space-evenly;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: nowrap;
    height: 100%;
    overflow: scroll;
    position: fixed;
}

.tp-story {
    height: 100%;
    display: flex;
    align-items: center;
    background: #FFFFFF;
}

.tp-main-container {
    background: black;
    display: flex;
    height: 4000px;
    position: relative;


}

.tp-4 {
    width:100%;
}

.tp-story-text-container {
    background: #000;
    height: 100%;
    overflow: hidden;
    font-size: 2.3em;
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;



}

.tp-story-text {
    position: absolute;
    margin-top: 40px;
    width: 50%;

}
.tp-story-text p{
    line-height: 1.7em;
    margin-bottom: 200px;

}

.tp-black{
    color: black;
}
.tp-highlight{
    animation: High-Lite infinite 10s ease-in-out;
}


@keyframes High-Lite {
    0% {
        color: #FFFFFF;
    }
    50% {
        color: #aaa;
    }
    100% {
        color: #FFFFFF;
    }
}


.tp-nice-12 {
    width: 8.33%;
    border-left: 1px dashed #777777;
}
.tp-nice-12-first {
    width: 8.33%;
    border-left: 0px dashed #777777;

}
.logo-main{
    margin: 0px auto;
}
.tp-grid-fix {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    z-index: 4000;
}
@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px) {

    .xs-hide{
        display: none;
    }
    .tp-story-text-container {
        font-size: 1.4em !important;
    }
    .tp-story-text{
        margin-top: 80px;
        width: 75%;
    }
    .tp-story-text p{
        margin-bottom: 200px;
        line-height: 3em;
    }
    .tp-black{
        color: #AAA;
    }
}
